@import "styles/resources";

.title-container {
  @include position(absolute, 0 null null $widget-size);
  padding-left: 4em;
  pointer-events: none;
  user-select: none;

  &.light {
    color: color("mineshaft");
  }

  &.dark {
    color: color("white");
  }

  .copy {
    line-height: $widget-size;
  }
}
