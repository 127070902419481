@import "styles/resources";

.product-card-copy {
  padding: 0.75em 0 0.5em 1em;

  li {
    font-size: $small;
    line-height: 1.25em;
  }
}

.product-card-primary-wrapper {
  @include flex(align-center);
  padding-bottom: 0.5em;
  color: color("cerulean");

  svg {
    @include size(0.75em);
    margin-right: 0.25em;
    fill: color("cerulean");
  }
}
