@import "styles/resources";

.plant-list-container {
  @include flex(column align-center justify-center);
  padding: 0 $widget-size 5em;

  .title-bar {
    @include flex(align-baseline);
    padding: 1em 0;
    color: color("alizarincrimson");

    svg {
      @include size(2.813em);
      margin-right: 1em;
      fill: color("alizarincrimson");
    }
  }

  .plant-list {
    @include flex(wrap justify-center);
    max-width: 100%;
  }

  .plant-card {
    margin: 1.5em 0.5em;
  }
}
