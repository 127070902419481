@import "styles/resources";

#side-bar {
  @include flex();
  width: $widget-size;
  position: relative;
  z-index: $z6;

  .overlay {
    @include position(fixed, 0);
    background-color: color("black");
    opacity: 0;
    transition: opacity 300ms;
    pointer-events: none;

    &.is-active {
      opacity: 0.8;
      pointer-events: auto;
    }
  }

  .widget-container {
    @include flex(column align-start);
  }

  .title-container {
    padding-left: 3em;
    pointer-events: none;
  }

  .drawer-container {
    z-index: $z8;
    pointer-events: none;

    &.is-active {
      pointer-events: auto;
    }
  }
}
