@import "styles/resources";

.tier-two-item {
  .menu-item {
    &:hover {
      .copy {
        color: color("alizarincrimson");
      }

      svg {
        fill: color("alizarincrimson");
      }
    }
  }
}
