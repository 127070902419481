@import "styles/resources";

#filtered-list-container {
  @include flex(align-start);
  height: 100%;

  .selector-wrapper {
    flex: 0 0 300px;
    height: 100%;
  }

  .list-wrapper {
    flex: 1 0 auto;
    height: 100%;
  }
}
