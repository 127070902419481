@import "styles/resources";

.tab {
  &.is-active {
    h5 {
      border-color: color("cerulean");
      color: color("dustygray");
    }
  }

  h5 {
    margin: 0;
    padding-bottom: 0.5em;
    border-bottom: 4px solid transparent;
    color: color("mineshaft");
    font-weight: 700;
    transition: border-bottom 300ms, color 300ms;

    &:hover {
      color: color("cerulean");
      cursor: pointer;
    }
  }
}
