@import "styles/resources";

.plant-card {
  @include flex(column align-center);
  width: 31.75rem;

  .image {
    @include flex(align-center justify-center);
    @include size(31.75rem, 22.188rem);
    position: relative;
    z-index: 0;
    overflow: hidden;

    img {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      z-index: -1;
      // IE11 polyfill
      font-family: "object-fit: cover;";
      object-fit: cover;
    }

    .overlay {
      @include position(absolute, 0);
      z-index: 1;
      background-color: color("black");
      opacity: 0;
      transition: opacity 300ms;
    }

    .plus-icon {
      @include position(absolute, 50% null null 50%);
      z-index: 2;
      opacity: 0;
      transform: translate(-50%, -50%);
      transition: opacity 300ms;

      svg {
        @include size(2em);
        fill: color("white");
      }
    }

    &:hover {
      .overlay {
        opacity: 0.8;
      }

      .plus-icon {
        opacity: 1;
      }
    }
  }

  .plant-card-title {
    margin-top: 1em;
    color: color("mineshaft");
    font-size: $h5;
    transition: color 300ms;
  }

  &.is-active {
    .overlay {
      opacity: 0.8;
    }

    .plus-icon {
      opacity: 1;
    }

    .plant-card-title {
      color: color("alizarincrimson");
    }
  }
}
