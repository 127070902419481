@import "styles/resources";

.img-title {
  @include flex(justify-start align-center);
  background: transparent;
  color: color("mineshaft");
  transition: background 300ms, color 300ms;
  cursor: pointer;

  svg {
    @include size(4.3em);
    margin-right: 1em;
    padding: 1em;
    fill: color("mineshaft");
    transition: background 300ms, fill 300ms;
  }

  &:hover {
    background: color("white");
    color: color("cerulean");

    svg {
      background: color("cerulean");
      fill: color("white");
    }
  }

  &.is-active {
    background: color("white");
    color: color("cerulean");
    svg {
      background: color("white");
      fill: color("cerulean");
    }
  }

  &.is-disabled {
    color: color("dustygray");
    cursor: default;
    pointer-events: none;

    svg {
      fill: color("dustygray");
    }
  }
}
