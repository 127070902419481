@import "styles/resources";

#scroll-indicator {
  @include flex(column align-center justify-center);
  @include position(absolute, null 0 0 0);
  height: 30px;
  z-index: $z5;
  background-color: color("wildsand");

  .semi-circle-wrap {
    @include flex(column align-center justify-center);
    @include position(absolute, null 0 0 0);
    transform: translateY(12px);

    .semi-circle {
      @include size(80px);
      z-index: -1;
      margin: 0 auto;
      border-radius: 50%;
      background-color: color("wildsand");
    }
  }

  .inner {
    @include flex(column align-center justify-center);
    @include position(absolute, null 0 0 0);
    transform: translateY(-20px);
  }

  svg {
    @include size(1em);
    fill: color("mineshaft");
  }

  .copy {
    margin-top: 0.75em;
    color: color("mineshaft");
    font-size: 10px;
    text-transform: uppercase;
  }
}
