@import "styles/resources";

.landing-cta {
  @include flex(column justify-center align-center);
  height: 500px;
  text-align: center;
  transition: background 300ms, color 300ms;

  &.is-hovered {
    background: color("alizarincrimson");

    .cta-title,
    .cta-body {
      color: color("white");
    }

    svg {
      fill: color("white");
    }

    .cta-link {
      background: color("white");
      color: color("alizarincrimson");
    }
  }

  .cta-title {
    margin-top: 0.5em;
    color: color("mineshaft");
    font-size: 5em;
    font-weight: 600;
    line-height: 1em;
    transition: color 300ms;
  }

  .cta-body {
    max-width: 375px;
    margin: 1em auto 2.5em;
    color: color("emperor");
    transition: color 300ms;
  }

  svg {
    @include size(7em);
    fill: color("alizarincrimson");
    transition: fill 300ms;
  }

  .cta-link {
    padding: 0.8em 1.9em;
    background: color("alizarincrimson");
    color: color("white");
    font-weight: 700;
    text-transform: uppercase;
    transition: background 300ms, color 300ms;
    cursor: pointer;
  }
}
