@import "styles/resources";

#hamburger-container {
  @include flex(align-center justify-center);
  @include size($widget-size);
  cursor: pointer;

  svg {
    @include size(2em);
  }

  &.none {
    background-color: color("alizarincrimson");

    svg {
      fill: color("white");
    }
  }

  &.light {
    svg {
      fill: color("mineshaft");
    }
  }

  &.dark {
    svg {
      fill: color("white");
    }
  }
}
