@import "styles/resources";

.landing-page {
  position: relative;
  margin: 2em auto;

  .intro-group {
    @include flex(align-start);
    margin: 2em 0 0;
  }

  .logo {
    @include position(absolute, 0 null null 2em);

    svg {
      @include size(9.25em, 3.5em);
      fill: color("alizarincrimson");
    }
  }

  .intro-text {
    margin: 1em auto 3em;
    text-align: center;
  }

  .title {
    color: color("mineshaft");
    font-weight: 700;
    line-height: 1.875rem;
  }

  .body {
    max-width: 700px;
    margin: 1em auto;
    color: color("emperor");
    line-height: 1.875rem;
  }

  .landing-sides {
    @include flex(justify-between align-start);

    .landing-cta {
      flex: 1 0 500px;

      &:first-of-type {
        border-right: 1px solid color("dustygray");
      }
    }
  }
}
