@import "styles/resources";

#unit-container {
  position: relative;
  z-index: $z1;

  .tab-panel-container {
    @include position(absolute, 0);
    z-index: 1;
    transform: translateY(-100%);
    transition: transform 300ms 300ms;

    &.is-active {
      z-index: 2;
      transform: translateY(0);
      transition: transform 300ms;
    }

    &.no-delay {
      transition: transform 300ms;
    }
  }
}
