/**
 * color map
 * names determined using tool located at: http://chir.ag/projects/name-that-color
 */
$colors: (
  "alizarincrimson": #e2231a,
  "black": #000,
  "cerulean": #00b5e2,
  "dark-jungle": #202020,
  "dustygray": #999999,
  "emperor": #525252,
  "mineshaft": #282828,
  "tide": #bab4af,
  "white": #fff,
  "wildsand": #f4f4f4
);

/* font settings */
$primary-font: "Helvetica Neue", Arial, Helvetica, sans-serif;

// px values at 16px em base
$base: 1rem; // 16px
$p: 1.125rem; // 18px
$h1: 2.0625rem; // 33px
$h2: 1.875rem; // 30px
$h3: 1.5rem; // 24px
$h4: 1.25rem; // 20px
$h5: 1.125rem; // 18px
$small: 0.875rem; // 14px

/* properties */

$butt-bar-item-width: 13.125rem;
$butt-bar-height: 6.875rem;
$widget-size: 3.875rem;
$drawer-width: 345px;
$menu-width: 310px;
$lit-sidebar: 37.5rem;

/* z-index levels */
$z0: 0;
// views
$z1: 9;
$z2: 99;
// tabcontent
$z3: 999;
// butt bar
$z4: 9999;
$z5: 99999;
// side bar
$z6: 999999;
$z7: 9999999;
// modal & drawer
$z8: 99999999;
$z9: 999999999;

/* responsiveness */
$breakpoints: (
  tablet: 960px
);
