@import "styles/resources";

// vars
$svg-size: 10em;
$svg-size-sm: 8em;

.loading-screen {
  @include flex(column align-center justify-center);
  @include position(fixed, 0 0 0 0);
  z-index: $z9;
  background: color("mineshaft");
  transform: translate3d(0, -100%, 0);
  transition: transform 300ms 1000ms;

  &.is-active {
    transform: translate3d(0, 0, 0);
    transition: none;
  }

  .icon-wrapper {
    @include size($svg-size);
    margin-bottom: 1em;

    svg {
      @include size($svg-size);
      overflow: visible;
      stroke-width: 0.5px;
      stroke-linecap: round;
      stroke-dasharray: 70;
      stroke: color("wildsand");
      fill: color("alizarincrimson");
      animation: stroker 10s infinite linear;
      stroke-linejoin: round;
    }
  }

  .message {
    color: color("wildsand");
    font-weight: bold;
    letter-spacing: 3px;
  }

  &.is-relative {
    @include position(absolute, 0 0 0 0);
    z-index: $z3;
    background: color("white");

    .icon-wrapper {
      @include size($svg-size-sm);
      margin-bottom: 0.5em;
    }

    svg {
      @include size($svg-size-sm);
      stroke: color("alizarincrimson");
      fill: scale-color(color("alizarincrimson"), $lightness: 70%);
    }

    .message {
      color: color("mineshaft");
    }
  }
}

@keyframes stroker {
  to {
    stroke-dashoffset: 420;
  }
}
