html,
body {
  @include size(100%);
  overflow: hidden;
}

// lock in images to the viewport width site-wide
img {
  max-width: 100%;
  height: auto;
}

// remove the additional border highlighting
*:focus {
  outline: none;
}

html {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
  background-repeat: no-repeat;
}

table {
  width: 100%;
  margin: 2em 0;
  border-collapse: collapse;

  @include media(">=1200px") {
    width: 90%;
  }

  @include media(">=1515px") {
    width: 80%;
  }

  thead {
    th:first-child {
      @include border-color(transparent null null transparent);
      background: transparent;
    }

    th {
      height: 75px;
      padding: 1em 1.5em;
      vertical-align: middle;
    }
  }

  tbody {
    th {
      width: 295px;
    }
  }

  th {
    background: color("wildsand");
    color: color("mineshaft");
    font-weight: 700;
    text-align: left;
    vertical-align: top;
  }

  th,
  td {
    padding: 1.5em;
    border: 1px solid color("dustygray");
    font-size: $p;
    line-height: 1.375rem;
    vertical-align: top;
  }
}
