@import "styles/resources";

.pager-item {
  @include flex(align-center);
  background: color("dustygray");
  color: color("white");
  transition: background 0.3s;
  cursor: pointer;

  &:hover {
    background: color("alizarincrimson");
  }

  &.previous {
    padding-right: 1em;
    padding-left: 2em;

    &:hover svg {
      transform: translateX(-0.25em);
    }

    .chevron-nav {
      margin-right: 1em;
    }

    .pager-type {
      left: 0;
    }
  }

  &.next {
    flex-direction: row-reverse;
    padding-right: 2em;
    padding-left: 1em;

    &:hover svg {
      transform: translateX(0.25em);
    }

    .chevron-nav {
      margin-left: 1em;
    }

    .pager-type {
      right: 0;
    }
  }

  .chevron-nav {
    @include flex();
  }

  svg {
    @include size(1.5em);
    fill: color("white");
    transform: translateX(0);
    transition: transform 300ms;
  }

  .item-info {
    @include flex(align-center);
    height: 100%;
    position: relative;
    text-transform: uppercase;

    .pager-type {
      @include position(absolute, 1.5em null null null);
      font-size: $small;
    }
  }
}
