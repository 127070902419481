@charset "UTF-8";

@mixin article() {
  //$props: inspect($properties);
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.75em;
  }

  p {
    margin-bottom: 1em;
  }

  ul {
    padding-bottom: 1em;

    li {
      line-height: 1.375rem;

      ul {
        padding-top: 0.5em;
      }
    }
  }
}
