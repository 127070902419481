@import "styles/resources";

#drawer-footer {
  @include flex(column);
  max-width: 280px;
  margin: 0 auto;
  padding-bottom: 1em;
  color: color("white");

  > * {
    margin-bottom: 1em;
  }
}
