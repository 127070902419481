@import "styles/resources";

.transit-wrapper {
  @include position(absolute, 0);
  z-index: $z3;
  opacity: 0;
  transform: translateZ(0);
  transition: opacity 100ms;
  pointer-events: none;

  &.is-active {
    opacity: 1;
    pointer-events: auto;
  }

  video {
    @include size(100%);
    // IE11 polyfill
    font-family: "object-fit: cover; object-position: center center";
    object-fit: cover;
    object-position: center center;
  }
}
