@import "styles/resources";

.menu-item {
  @include flex(align-center justify-start);
  margin: 0.25em 0;
  padding: 1em 2em;
  cursor: pointer;

  .copy {
    flex: 1 0 0;
    color: color("mineshaft");
    font-size: $base;
    transition: color 300ms;
  }

  svg {
    @include size(1em);
    fill: color("mineshaft");
    transform: translateX(0);
    transition: fill 300ms, transform 300ms;
  }

  &:hover {
    svg {
      transform: translateX(0.25em);
    }
  }
}
