@import "styles/resources";

#product-view {
  @include flex(justify-between);
  @include size(100%);
  z-index: $z1;

  .left-side-wrap {
    flex: 0 1 100%;
    min-width: 20%;
    height: 100%;
    position: relative;
  }

  .card-list {
    @include position(absolute, 0 0 0 0);
    @include size(100%);
    z-index: 1;
    background: color("white");
    transform: translateY(-100%);
    transition: transform 300ms 300ms;
    pointer-events: none;

    &.is-active {
      z-index: 2;
      transform: translateY(0);
      transition: transform 300ms;
      pointer-events: auto;
    }

    &.no-delay {
      transition: transform 300ms;
    }
  }

  .lit-sidebar {
    flex: 0 0 $lit-sidebar;
  }
}

#product-error {
  @include flex(column align-center justify-center);
  @include size(100%);

  > svg {
    @include size(10em);
    fill: color("wildsand");
  }

  .questionable {
    @include flex(align-center justify-center);
    @include position(absolute, 0);

    svg {
      @include size(3em);
      fill: color("alizarincrimson");
    }
  }

  .copy {
    margin-top: 1em;
    color: color("mineshaft");
    font-weight: bold;
    letter-spacing: 3px;
  }
}
