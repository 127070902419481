@import "styles/resources";

.side-bar-widget {
  @include flex(align-center);
  @include size($widget-size);
  z-index: 0;
  overflow: visible;
  cursor: pointer;

  .widget-wrapper {
    @include flex();
    position: relative;
    pointer-events: none;

    .background {
      @include position(absolute, 0);
      z-index: -1;
      background-color: transparent;
      transform: translate3d(calc(-100% + #{$widget-size}), 0, 0);
      transition: background-color 300ms, transform 300ms;
    }
  }

  .widget-icon {
    @include flex(align-center justify-center);
    @include size($widget-size);
    min-width: $widget-size;

    svg {
      @include size(2em);
      transition: fill 300ms;
    }
  }

  .widget-copy {
    @include flex(align-center);
    min-width: 100%;
    padding-right: 1.5em;
    overflow: hidden;
    color: color("white");
    user-select: none;

    .inner {
      opacity: 0;
      white-space: nowrap;
      transform: translateX(-30%);
      transition: transform 300ms, opacity 300ms;
    }
  }

  // theming
  &.light {
    .widget-icon svg {
      fill: color("mineshaft");
    }
  }

  &.dark {
    .widget-icon svg {
      fill: color("white");
    }
  }

  &.empty {
    pointer-events: none;
  }

  &:hover {
    .widget-wrapper {
      pointer-events: auto;
    }

    .background {
      background-color: color("alizarincrimson");
      transform: translate3d(0, 0, 0);
    }

    .widget-icon svg {
      fill: color("white");
    }

    .inner {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
