@import "styles/resources";

.full-background {
  @include position(absolute, 0);

  img {
    @include size(100%);
    object-fit: cover;
    // IE11 polfyill
    font-family: "object-fit: cover;";
  }

  &.is-disabled {
    display: none;
  }
}
