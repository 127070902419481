@import "styles/resources";

#drawer-header {
  @include flex(align-center justify-center);
  position: relative;
  background-color: color("alizarincrimson");

  svg {
    fill: color("white");
  }

  .logo-icon svg {
    @include size(8.5em, 3.125em);
  }

  .close-icon {
    @include flex(align-center justify-center);
    @include size($widget-size);
    @include position(absolute, 0 null null 0);
    cursor: pointer;

    svg {
      @include size(2em);
    }
  }
}
