@import "styles/resources";

.article-container {
  @include size(100%);

  .body {
    @include article();
    padding: 2em 3em 2em 0;
  }
}
